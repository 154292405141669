import {useTranslation} from "react-i18next";
import {Col, Layout} from "antd";

export const RSVP = () => {
    const {t } = useTranslation();
    return (
        <Layout style={{padding: '5%', display: 'flex', justifyContent: 'center'}}>
            <h1 style={{display: "flex", justifyContent: "center"}}>{t('confirmTitle')}</h1>
            <p style={{display: "flex", justifyContent: "center", fontStyle: "italic"}}>{t('confirmText')}</p>
            <Col style={{display: "flex", justifyContent: "center"}}>
                <iframe title={"googleFormSubmission"} src="https://docs.google.com/forms/d/e/1FAIpQLSf5Nl2EA6UcZX3DOAEfoRDS-hWhy18rCqUmkkBi97cd-xq30w/viewform?embedded=true" width="640" height="1700" frameBorder="0" marginHeight={0} marginWidth={0}>Chargement…</iframe>
            </Col>
        </Layout>

    )
}