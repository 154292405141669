import {Button, Col, Layout, Row} from "antd";
import React from "react";
import {COLORS} from "../App";
import {Content} from "antd/es/layout/layout";
import {useTranslation} from "react-i18next";

export const Home = () => {
    const {t} = useTranslation();
    return (
        <Content style={{
            padding: '0',
            background: COLORS.yellowGray,
            borderRadius: 0,  }}>
            <div className={"capsule"}>
                <div className={"flower-top"}></div>
                <div className={"pink-box"}>
                    <h1 className={"golden-title"}>SARAH & RAPHAËL</h1>
                </div>
                <div className={"flower-bottom"}></div>
            </div>
            <Row className={"wedding-information"} style={{display: "flex", padding: '5%'}}>
                <Col>
                    {t('married')}
                    <div className={"wedding-date"}>{t('weddingDate')}</div>
                </Col>
            </Row>
            <Row className={"confirm-venue"} style={{display: "flex", padding: '5%'}}>
                <Button type="primary" href="/rsvp" size={'large'}>
                    {t('confirmButton')}
                </Button>
            </Row>
            <Layout style={{display: 'flex', padding: '0 5%'}}>


                <Row className={'column-preview'}>
                    <Col className={'ceremony'}>
                        <h2 > 💍 {t('ceremony')}</h2>
                        <h3 >{t('ceremonyTime')} </h3>
                        <h4 > Mairie du 19e Arrondissement </h4>
                        <h4 >5 Place Armand Carrel, Paris</h4>
                    </Col>
                    <Col className={'reception'}>
                        <h2>🥂 {t('reception')}</h2>
                        <h3 > {t('receptionTime')} </h3>
                        <h4 > L'Automobile Club de France </h4>
                        <h4 > 6 Place de la Concorde, Paris</h4>
                    </Col>
                </Row>

            </Layout>
        </Content>
    )
}