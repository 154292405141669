import * as React from "react";
import {COLORS} from "./colors";
import {Menu, Layout, ConfigProvider} from "antd";
import {LanguageSwitch} from "./languageSwitch";
import {Link, useLocation} from "react-router-dom";
import {MenuOutlined} from "@ant-design/icons";

const headingStyles = {
    backgroundColor: COLORS.brown,
    color: COLORS.yellowGray,
    fontFamily: "Playfair Display",
    fontSize: 'large',
    fontWeight: 500,
}

export const MyHeader: React.FC<{}> = () => {
    const items : {key: string; label: string; path: string}[] = [{key: "1", label: "S & R", path: "/" }, {key:"2", label: "Information", path: "/faq"}, {key: "3", label: "RSVP", path: "/rsvp"}];
    const location = useLocation();
    const selectedItem = items.find((e)=> {return e.path === location.pathname } );

    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: COLORS.yellowGray,
                    borderRadius: 2,
                    // Alias Token
                    colorBgContainer: COLORS.brown,
                    colorTextBase: COLORS.yellowGray,
                },
                components: {
                    Menu: {
                        itemColor: COLORS.yellowGray,
                        itemSelectedColor: COLORS.darkBrown,
                        itemBg: '#f0f',
                        popupBg: COLORS.darkBrown,
                        subMenuItemBg: '#00f'
                    }
                }
            }}
        >
        <Layout.Header style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: COLORS.brown, alignItems: 'center'  }}>
                <Menu
                    mode="horizontal"
                    defaultSelectedKeys={['1']}
                    selectedKeys={[selectedItem?.key || '1']}
                    style={{ flex: 1 , minWidth: 0, border:0, ...headingStyles }}
                    overflowedIndicator={<MenuOutlined />}
                >
                    {
                        items.map((item) => {
                            return (
                                <Menu.Item key={item.key}>
                                    <Link to={item.path}>{item.label}</Link>
                                </Menu.Item>
                                );
                        })
                    }

                </Menu>
                <LanguageSwitch></LanguageSwitch>

        </Layout.Header>
        </ConfigProvider>
    )
}