import React from 'react';
import './App.css';
import {ConfigProvider, Layout} from "antd";
import {MyHeader} from "./components/HeaderMenu";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import enLocales from './locales/en/translation.json'
import frLocales from './locales/fr/translation.json'
import {Link, Route, Routes} from "react-router-dom";
import {Home} from "./components/Home";
import {RSVP} from "./components/RSVP";
import {FAQ} from "./components/FAQ";

export const COLORS = {
  brown: "#D1B38D",
  pink: "#F6D3C9",
  yellowGray: "#F9F6F2",
  darkBrown:  "#906D38",
  gold: "#DBAB56"
}
const pageStyles = {
  color: COLORS.darkBrown,
  padding: "0px",
  fontFamily: "Montserrat, Roboto, sans-serif, serif",
  backgroundColor: COLORS.yellowGray,
  primaryColor: COLORS.pink,
  secondaryColor: COLORS.brown
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: {
          translation: enLocales
        },
        fr: {
          translation: frLocales
        }
      },
      lng: "en", // if you're using a language detector, do not define the lng option
      fallbackLng: "en",

      interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      }
    });

function App() {
  const { Footer} = Layout;
  return (
      <ConfigProvider
          theme={{
            token: {
              // Seed Token
              colorPrimary: COLORS.brown,
              borderRadius: 2,
              // Alias Token
              colorBgContainer: COLORS.yellowGray,
            },
          }}
      >
        <Layout style={pageStyles}>
          <MyHeader></MyHeader>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/rsvp" element={<RSVP />} />
          </Routes>
          <Footer style={{ textAlign: 'center', backgroundColor: COLORS.brown, color: COLORS.yellowGray }}>
              <p>Sarah & Raphaël - 19 October 2024</p>
              <div >
                  <div><Link to='/faq' style={{color: COLORS.darkBrown}}>Information</Link></div>
                  <div><Link to='/rsvp' style={{color: COLORS.darkBrown}}>RSVP</Link></div>
              </div>


          </Footer>
        </Layout>
      </ConfigProvider>
  );
}

export default App;
