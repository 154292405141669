import {Card, Col, Collapse, CollapseProps, List, Row} from "antd";
import React from "react";
import {COLORS} from "../App";
import {Content} from "antd/es/layout/layout";
import {Trans, useTranslation} from "react-i18next";
import bottomLine from './../images/bottom-line.png';
import mapAutomobileClub from './../images/map-automobile-club.png';
import {Link} from "react-router-dom";
import i18n from "i18next";

// define hotel as an typescript interface
interface Hotel {
    name: string;
    price: string;
    link: string;
    stars: string;
}
const HotelsList = ({hotels}: {hotels: Hotel[]} ) => {
    const {t} = useTranslation();

    return (
        <>
            <Row>{t('faq.hotel.answer')}</Row>
            {hotels.map(({name, price, link, stars}) => {
                return (
                    <Row style={{display:"flex", justifyContent:"left"}}>
                    <Trans i18nKey={'faq.hotel.hotelDetails'}>
                        {{name}} : Dans cet hotel {{stars}} étoiles, les chambres sont disponibles à partir de {{price}} par nuit. <Link className={'hotelLink'} to={link}>Contacter l'hotel pour réserver</Link>
                    </Trans>
                    </Row>
                )
            })}
        </>
    )

}
export const FAQ = () => {
    const {t} = useTranslation();
    const questions = ["where", "hotel", "parkingTownhall","afterCeremony", "venue", "parking",  "inside",  "food", "howToRSVP", "weddingList"];
    const hotels = [{name: "Ibis Budget La Villette", price: "c.140€", link: "https://all.accor.com/hotel/4982/index.fr.shtml", stars: "2" }, {name: "Ibis Styles Buttes Chaumont", price: "c.180€", link: "https://all.accor.com/hotel/9586/index.fr.shtml", stars: "3"}];
    return (
        <Content style={{
            padding: '5%',
            background: COLORS.yellowGray,
            borderRadius: 0,  }}>
            <Row className={"wedding-information"}>
                <Col>
                    {t('married')}
                    <div className={"wedding-date"}>{t('weddingDate')}</div>
                </Col>
            </Row>
            <Row style={{display:"flex", justifyContent:"center"}}>
                <img style={{maxWidth: '100%'}} src={bottomLine} alt={'logo'}/>
            </Row>
            <List
                itemLayout="horizontal"
                dataSource={questions}
                renderItem={(item, index) => {
                    if (item === "hotel") {
                        return (
                            <List.Item>
                                <List.Item.Meta
                                    title={t('faq.'+item+'.question')}
                                    description={<HotelsList hotels={hotels}/>}
                                    style={{display:"flex", justifyContent:"center"}}
                                />
                            </List.Item>
                            )


                    }
                    if (item === "howToRSVP") {
                        return (
                            <List.Item>
                                <List.Item.Meta
                                    title={t('faq.'+item+'.question')}
                                    description={<Link to={'/rsvp'}>{t('faq.'+item+'.answer')}</Link>}
                                />
                            </List.Item>
                        )
                    }
                    // if language is french, display the bank account details
                    if (item === "weddingList" && i18n.language === 'fr') {
                        const items: CollapseProps['items'] = [
                            {
                                key: '1',
                                label: 'Compte bancaire de notre voyage de Noce',
                                children: <Card title="Boursorama" >
                                    <h4 style={{fontFamily:"monospace", color: "darkred"}}>BIC:</h4><p style={{fontFamily:"monospace"}}>BOUS FRPP XXX</p>
                                    <h4 style={{fontFamily:"monospace", color: "darkred"}}>IBAN:</h4><p style={{fontFamily:"monospace"}}>FR76 4061 8804 4300 0403 1391 324</p>
                                    <h4 style={{fontFamily:"monospace", color: "darkred"}}>Titulaire:</h4><p style={{fontFamily:"monospace"}}>M. MOUTARD Raphaël</p>
                                </Card>,
                            }];
                        return (
                            <>
                                <List.Item>
                                    <List.Item.Meta
                                        title={t('faq.'+item+'.question')}
                                        description={t('faq.'+item+'.answer')}
                                    />
                                </List.Item>
                                <Collapse items={items} defaultActiveKey={['1']}>
                                </Collapse>
                            </>

                        )
                    }
                    if (item === "weddingList") {
                        return <></>
                    }
                    return (
                        <List.Item>
                            <List.Item.Meta
                                title={t('faq.'+item+'.question')}
                                description={t('faq.'+item+'.answer')}
                            />
                        </List.Item>
                    )
                }
                }
            />
            <Row style={{display:"flex", justifyContent:"center"}}>
                <img src={mapAutomobileClub} alt={'map'} style={{maxWidth: '100%', maxHeight: '500px'}}/>
            </Row>
        </Content>
    )
}