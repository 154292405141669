import { Radio } from "antd"
import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
export const LanguageSwitch: React.FC<{}> = () => {
    const { i18n } = useTranslation()
    const [selectedLanguage, setSelectedLanguage] = useState('en')
    const changeLanguage = (language: string) => {
        setSelectedLanguage(language);
        i18n.changeLanguage(language);
    }
    return (
        <Radio.Group className={"languageSwitch"} value={selectedLanguage} onChange={(e) =>  changeLanguage(e.target.value)}>
            <Radio.Button value="en">English</Radio.Button>
            <Radio.Button value="fr">French</Radio.Button>
        </Radio.Group>
        );

}